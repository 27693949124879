<template>
    <div>
        <card-header :header="topheaders"></card-header>
        <div class="mb-4">
            <v-btn text rounded color="secondary" @click="backToList">
                <v-icon class="mr-2">{{ icons.mdiKeyboardBackspace }}</v-icon>
                Back to home
            </v-btn>
        </div>
        <v-card class="">
            <v-container class="pt-8 pb-10">
                <v-row justify="center">
                    <v-col cols="11" class="px-0">
                        <div class="d-flex justify-space-between flex-wrap">
                            <h1 class="primary--text">
                                Create new job offer
                            </h1>
                        </div>
                        <hr class="hr-l mb-4 mt-2">
                    </v-col>
                </v-row>
            </v-container>
            <v-card-text>
                <v-form>
                    <divider-text class="my-4">Vacancie domain</divider-text>
                    <v-btn-toggle v-model="toggledomain" group dense color="secondary" class="d-flex flex-row flex-wrap"
                        borderless>
                        <v-btn v-for="(btnvalue, index) in domain_list" :key="index" small
                            class="mr-0 mb-2 rounded-pill smbord" elevation="0" :value="btnvalue">
                            {{ btnvalue }}
                        </v-btn>
                    </v-btn-toggle>
                    <divider-text class="my-4">Vacancie details</divider-text>
                    <label class="body-1">Title</label>
                    <v-text-field v-model="jobtitle" label="Title" type="text" outlined dense
                        :hide-details="jobtitleErrors.isvalid" :error-messages="jobtitleErrors.value"
                        @input="$v.jobtitle.$touch()" @blur="$v.jobtitle.$touch()" class="mb-2" placeholder="Title">
                    </v-text-field>
                    <div class="d-flex flex-row align-center mt-4">
                        <v-radio-group v-model="periodicity" hide-details mandatory row class="mb-0 mt-0">
                            <label class="body-1 mr-4">Periodicity</label>
                            <v-radio v-for="(item, i) in ['Full time', 'Middle', 'Altrnance']" :value="item"
                                :key="i" color="secondary" hide-details class="pb-0 mb-0">
                                <template v-slot:label>
                                    <div>
                                        <span class="seconday--text">{{ item }}</span>
                                    </div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </div>
                    <div class="d-flex flex-row row mt-2">
                        <div class="d-flex flex-column col-12 col-md-4">
                            <label for="datemodel" class="mr-4">Country</label>
                            <v-combobox v-model="selectedcountry" :items="countrylist" item-text="name"
                                :auto-select-first="true" :cache-items="false" flat return-object dense filled outlined
                                solo hide-details>
                            </v-combobox>
                        </div>
                        <div class="d-flex flex-column col-12 col-md-4">
                            <label for="datemodel" class="mr-4">Location</label>
                            <v-combobox v-model="selectedlocation" :auto-select-first="true" :cache-items="false" flat
                                dense filled outlined solo hide-details>
                            </v-combobox>
                        </div>
                        <div class="d-flex flex-column col-12 col-md-4">
                            <label for="datemodel" class="mr-4">Deadline submission</label>
                            <!-- star datepicker -->
                            <v-menu v-model="datemenu" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex flex-row align-center">
                                        <v-text-field v-model="dateline" hide-details dense outlined
                                            :prepend-inner-icon="icons.mdiCalendar" readonly v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </div>
                                </template>
                                <v-date-picker v-model="dateline" @input="datemenu = false" class="mt-4">
                                </v-date-picker>
                            </v-menu>
                            <!-- End datepicker -->
                        </div>
                    </div>
                    <divider-text class="mb-2">Vacancie description</divider-text>
                    <vue-editor v-model="jobdescription" outlined dense :hide-details="jobdescriptionErrors.isvalid"
                        :error-messages="jobdescriptionErrors.value" class="text-left mb-3" label="Body"
                        placeholder="Enter your job description">
                    </vue-editor>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn type="reset" color="error" outlined @click="resetFirstStape">
                    Reset
                </v-btn>
                <v-btn color="secondary" :loading="loading" @click="submitJobOffer">
                    Pubish job offer
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import DialogCheckInfos from '@/views/dashboard/components/DialogCheckInfos.vue'
import { mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace } from '@mdi/js'
import { VueEditor } from "vue2-editor";
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import SelectRecipientu from '@/views/dashboard/requests/components/SelectRecipientu.vue'
import DividerText from '@/views/components/DividerText.vue';
import CardHeader from '@/views/dashboard/components/CardHeader.vue'

export default {
    mixins: [validationMixin],
    validations: {
        jobtitle: { required },
    },
    components: {
        CardHeader,
        SelectRecipientu,
        DialogCheckInfos,
        VueEditor,
        DividerText
    },
    data() {
        return {
            topheaders: {
                titlepage: 'VACANCIES',
                icon: null,
            },
            dateline: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            datemenu: false,
            loading: false,
            periodicity: '',
            toggledomain: "Business Et Entreprenariat",
            jobtitle: '',
            jobdescription: '',
            selectedlocation: 'Bilda',
            selectedcountry: { "id": "3", "name": "ALGERIA", "iso": "DZ", "phonecode": "213", "status": "0" },
            countrylist: [],
            domain_list: [
                'Business Et Entreprenariat',
                'Énergies Renouvelables Et Électricité',
                'Éducation Et Formation',
                'Pétrole Et Gaz',
                'TICs Et Transformation Numérique',
                'Économie, Politique Et Droit',
                'Mines',
                'Agriculture Et Agro-Industrie',
                'Others'
            ],
            jobitem: {
                dateline: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                datemenu: false,
                loading: false,
                periodicity: '',
                toggledomain: "Business Et Entreprenariat",
                jobtitle: '',
                jobdescription: '',
                selectedlocation: 'Bilda',
                selectedcountry: { "id": "3", "name": "ALGERIA", "iso": "DZ", "phonecode": "213", "status": "0" },
                countrylist: [],
            }
        }
    },

    computed: {
        jobtitleErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.jobtitle.$dirty) return errors
            !this.$v.jobtitle.required && errors.value.push('Object is required.') && (errors.isvalid = false)
            return errors
        },
        jobdescriptionErrors() {
            const errors = { isvalid: true, value: [] }
            if (this.jobdescription.length > 0) {
                errors.isvalid = true
            } else {
                errors.value.push('Request content musn\'t be null')
                errors.isvalid = false
            }
            return errors
        },
    },
    methods: {
        backToList() {
            this.$router.push({ name: 'company.vacancies' })
        },
        resetFirstStape() {
            this.$v.$reset()
            this.jobtitle = ''
            this.jobdescription = ''
            this.dateline = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.selectedcountry = { "id": "3", "name": "ALGERIA", "iso": "DZ", "phonecode": "213", "status": "0" }
            this.selectedlocation = "Bilda"
            this.periodicity = "Full time"
        },
        checkValidationForm() {
            if (this.jobdescriptionErrors.isvalid
                && this.jobtitleErrors.isvalid) {
                return true
            }
            else {
                return false
            }
        },

        submitJobOffer() {
            var bodyFormData = {
                "job": {
                    "title": this.jobtitle,
                    "enterprise.id": this.$store.getters.getCompany.id,
                    "description": this.jobdescription,
                    'dateline': this.dateline,
                    "country.id": this.selectedcountry.id,
                    "location": this.selectedlocation,
                    "periodicity": this.periodicity,
                    // "domain": this.toggledomain
                }
            }
            this.$v.$touch()
            if (this.checkValidationForm()) {
                this.loading = true;
                Drequest.api("job.create")
                    .data(bodyFormData)
                    .raw((response) => {
                        if (response.success) {
                            this.loading = false;
                            this.$fire({
                                type: "success",
                                text: response.detail,
                                timer: 3000
                            })
                            this.$router.push({ name: 'company.vacancies', params: { company: this.$store.getters.getCompany.name } })
                        }
                        else {
                            this.loading = false;
                            this.$fire({
                                type: "error",
                                text: response.error,
                                timer: 3000
                            }).then(r => {
                                console.log(r.value);
                            });
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                    })
            }
        },
    },
    beforeMount() {
        Drequest.api("lazyloading.country").get((response) => {
            if (response.success === true) {
                response.listEntity.forEach((item, index) => {
                    this.countrylist.push(item)
                })
            }
        }).catch((err) => {
            alert('Server error')
            this.loaderdata = false
        });

        if (this.$route.name.split(".")[2] === 'edit') {
            Drequest.api(`lazyloading.job?dfilters=on&id:eq=${this.$route.params.jobid}&enterprise_id:eq=${this.$store.getters.getCompany.id}`).get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((item, index) => {
                        // this.jobitem = item
                        this.dateline = item.dateline
                        this.datemenu = item.datemenu
                        this.periodicity = item.periodicity
                        // this.toggledomain = item."Business Et Entreprenariat"
                        this.jobtitle = item.title
                        this.jobdescription = item.description
                        this.selectedlocation = item.location
                        this.selectedcountry = item.country
                    })
                    this.loading = false
                }
            }).catch((err) => {
                alert('Server error')
                this.loaderdata = false
            });
        }
    },
    setup() {
        const icons = { mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace }

        return {
            icons
        }
    },

}
</script>
<style lang="scss" scoped>
@import '@/styles/styles.scss';

.smbord {
    margin-right: 0.4em !important;
}

.smbord:last-child {
    margin-right: 0px;
}
</style>